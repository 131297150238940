import React, { useEffect, useState } from "react";
import Axios from "../../_services/caller.service";
import styles from "./DiracLicence.module.scss";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import Modal from "../UiComponents/modals/Modal";
import useModal from "../../hooks/useModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import Button from "../UiComponents/Button";
import InputForms from "../UiComponents/forms/InputForms";
import Select from "react-select";

const DiracLicence = () => {
  const [editRowId, setEditRowId] = useState(null);
  const [formData, setFormData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [newDataList, setNewDataList] = useState([]);
  const [numberOfLines, setNumberOfLines] = useState(1);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [maj, setMaj] = useState(false);
  const [cabines, setCabines] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [allCabines, setAllCabines] = useState([]);

  const { isShowing, toggle } = useModal();
  const columnOrder = [
    "ID",
    "CompteHS",
    "cabids",
    "Login",
    "mdp",
    "LicenceKey",
    "reductionCode",
    "Name",
    "Notes",
  ];

  // Déclaration de la fonction getCabineNamesFromIds avant son utilisation
  const getCabineNamesFromIds = (cabIds) => {
    if (!cabIds) return "";
    const ids = cabIds.split(",").map((id) => parseInt(id.trim()));
    const cabineNames = ids.map((id) => {
      const cabine = allCabines.find((cab) => cab.id === id);
      return cabine ? cabine.cabname : "";
    });
    return cabineNames.filter((name) => name !== "").join(", ");
  };


  const sortedData = tableData.sort((a, b) => a.ID - b.ID);

  const filteredData = sortedData.filter((row) => {
    const cabineNamesString = getCabineNamesFromIds(row.cabids);
    return (
      row.CompteHS.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cabineNamesString.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    Axios.get("/diracLicence")
      .then((response) => {
        if (response.data && response.data.data) {
          setTableData(response.data.data);
        } else {
          console.error(
            "Erreur : Les données de licence ne sont pas disponibles."
          );
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des licences :", error);
      });
  }, [maj]);

  useEffect(() => {
    Axios.get("/getAllUserForSuperAdmin")
      .then((response) => {
        if (response.data && response.data.data) {
          setUsers(response.data.data);
        } else {
          console.error(
            "Erreur : Les données des utilisateurs ne sont pas disponibles."
          );
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des utilisateurs :",
          error
        );
      });
  }, []);

  const handleEdit = (rowId, rowData) => {
    setEditRowId(rowId);
    const currentRowData = tableData.find((row) => row.ID === rowData.ID);
    if (!currentRowData) {
      console.error("Erreur : Données de la ligne introuvables", rowData.ID);
      return;
    }

    setFormData({
      ...currentRowData,
      cabids: currentRowData.cabids
        ? currentRowData.cabids
            .split(",")
            .map((cabId) => parseInt(cabId.trim()))
        : [],
      CompteHS: currentRowData.CompteHS || "",
    });

    const userId = extractUserIdFromCompteHS(currentRowData.CompteHS);
    setSelectedUserId(userId);
    if (!userId) setCabines([]);
  };

  const extractUserIdFromCompteHS = (compteHSValue) => {
    if (!compteHSValue) return null;
    const matches = compteHSValue.match(/\d+$/);
    return matches ? matches[0] : null;
  };

  const getUsedCabines = () => {
    const usedCabines = new Set();
    tableData.forEach((row) => {
      if (row.ID !== formData.ID && row.cabids) {
        row.cabids.split(",").forEach((cabId) => {
          usedCabines.add(parseInt(cabId.trim()));
        });
      }
    });
    return usedCabines;
  };
  const handleCompteHsChange = (e, key) => {
    // Récupérer la valeur sélectionnée
    const value = e.target.value || "";

    // Met à jour `formData` avec la nouvelle valeur de CompteHS et réinitialise `cabids` si "Aucun utilisateur" est sélectionné
    setFormData({
      ...formData,
      [key]: value,
      cabids: value === "" ? [] : formData.cabids, // Réinitialise les cabines
    });

    // Si aucun utilisateur est sélectionné, réinitialise également `cabines`
    if (value === "") {
      setCabines([]); // Vide la liste des cabines disponibles
    }

    // Extraire l'ID utilisateur s'il est présent à la fin de la chaîne de caractères
    const matches = value.match(/\d+$/);
    const userId = matches ? matches[0] : null;

    // Met à jour `selectedUserId` avec l'ID extrait ou `null` si "Aucun utilisateur" est sélectionné
    setSelectedUserId(userId);
  };

  const handleSave = () => {
    if (!formData.ID || !formData.cabids) {
      toast.error("Les données du formulaire sont incomplètes.");
      return;
    }

    const data = { ...formData };
    data.cabids = Array.isArray(data.cabids)
      ? data.cabids.join(",")
      : data.cabids;

    Axios.put(`/updateDiracLicence/${data.ID}`, data)
      .then((response) => {
        const rowIndex = tableData.findIndex((item) => item.ID === data.ID);
        if (rowIndex !== -1) {
          const updatedDataList = tableData.map((item) =>
            item.ID === data.ID ? { ...item, ...data } : item
          );
          setTableData(updatedDataList);
        }
        toast.success("Modification effectuée avec succès");
        setEditRowId(null);
        setMaj(!maj);
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour", error);
        toast.error("Erreur lors de la mise à jour");
      });
  };

  const handleCabineChange = (selectedOptions, key) => {
    const selectedCabines = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, [key]: selectedCabines });
  };

  const handleInputChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast("Copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const submitLines = () => {
    const linesToCreate = Array.from({ length: numberOfLines }, () => ({
      reductionCode: "",
      Name: "",
      Login: "",
      mdp: "",
      LicenceKey: "",
      CompteHS: "",
      cabids: "",
      Notes: "",
    }));

    Axios.post("/createMultipleLicences", { lines: linesToCreate })
      .then((response) => {
        const updatedDataList = [...tableData, ...response.data.newLines];
        setTableData(updatedDataList);

        setEditRowId(null);
        setFormData({});
        toast.success("Les lignes ont été créées avec succès");
        toggle();
      })
      .catch((error) => {
        console.error("Erreur lors de la création des lignes", error);
        toast.error("Erreur lors de la création des lignes");
      });
  };

  useEffect(() => {
    if (selectedUserId) {
      Axios.get(`/getCabinesIdByCenterAndCabname/${selectedUserId}`)
        .then((response) => {
          if (response.data && response.data.data) {
            const usedCabines = getUsedCabines();
            const availableCabines = response.data.data.filter(
              (cab) => !usedCabines.has(cab.id)
            );
            setCabines(availableCabines);
          } else {
            console.error("Erreur : Les cabines ne sont pas disponibles.");
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des cabines :", error);
        });
    }
  }, [selectedUserId, tableData]);

  useEffect(() => {
    Axios.get(`/allCabines/`)
      .then((response) => {
        if (response.data && response.data.data) {
          setAllCabines(response.data.data);
        } else {
          console.error(
            "Erreur : Les données des cabines ne sont pas disponibles."
          );
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération de toutes les cabines :",
          error
        );
      });
  }, []);

  return (
    <div className={styles.containerDiracLicence}>
      <div className={styles.containersearchAndButton}>
        <InputForms
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IoMdAddCircleOutline
          className={styles.iconAddNewMics}
          onClick={() => toggle()}
          style={{ color: "#6d5dfc", fontSize: "50px", cursor: "pointer" }}
        />
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            {columnOrder.map((colKey) => (
              <th key={colKey} className={styles.th}>
                {colKey}
              </th>
            ))}
            <th className={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              {columnOrder.map((colKey) => {
                const value = row[colKey];

                if (colKey === "CompteHS" && editRowId === index) {
                  const selectOptions = [
                    { value: "", label: "Aucun utilisateur" },
                    ...users.map((user) => ({
                      value: `${user.user_login} - ${user.ID}`,
                      label: `${user.user_login} - ${user.ID}`,
                    })),
                  ];

                  return (
                    <td key={colKey} className={styles.td}>
                      <Select
                        options={selectOptions}
                        className={styles.selectUser}
                        value={selectOptions.find(
                          (option) => option.value === formData.CompteHS
                        )}
                        onChange={(selectedOption) =>
                          handleCompteHsChange(
                            { target: { value: selectedOption.value } },
                            "CompteHS"
                          )
                        }
                      />
                    </td>
                  );
                }

                if (colKey === "cabids") {
                  if (editRowId === index) {
                    const cabineOptions = cabines.map((cab) => ({
                      value: cab.id,
                      label: cab.cabname,
                    }));

                    return (
                      <td key={colKey} className={styles.td}>
                        <Select
                          isMulti
                          options={cabineOptions}
                          className={styles.selectCabine}
                          value={cabineOptions.filter((option) =>
                            formData.cabids.includes(option.value)
                          )}
                          onChange={(selectedOptions) =>
                            handleCabineChange(selectedOptions, "cabids")
                          }
                        />
                      </td>
                    );
                  } else {
                    return (
                      <td key={colKey}>{getCabineNamesFromIds(row.cabids)}</td>
                    );
                  }
                }

                if (["Login", "mdp"].includes(colKey) && editRowId !== index) {
                  return (
                    <td key={colKey} className={styles.td}>
                      <div>
                        {value}
                        <FiCopy
                          onClick={() => copyToClipboard(value)}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      </div>
                    </td>
                  );
                }

                if (colKey === "ID") {
                  return (
                    <td key={colKey} className={styles.td}>
                      {value}
                    </td>
                  );
                }

                return editRowId === index ? (
                  <td key={colKey} className={styles.td}>
                    <input
                      type="text"
                      className={styles.input}
                      value={formData[colKey]}
                      onChange={(e) => handleInputChange(e, colKey)}
                    />
                  </td>
                ) : (
                  <td key={colKey} className={styles.td}>
                    {value}
                  </td>
                );
              })}
              <td className={styles.td}>
                {editRowId === index ? (
                  <div className={styles.containerBtn}>
                    <button
                      className={styles.button}
                      onClick={() => handleSave(index)}
                    >
                      Save
                    </button>
                    <button
                      className={styles.buttonCancel}
                      onClick={() => {
                        setEditRowId(null);
                        setFormData({});
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    className={styles.button}
                    onClick={() => handleEdit(index, row)}
                  >
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal isShowing={isShowing} hide={toggle}>
        <div className={styles.containerModal}>
          <InputForms
            type="number"
            value={numberOfLines}
            onChange={(e) => setNumberOfLines(e.target.value)}
            placeholder="Nombre de lignes"
          />
          <Button
            onPress={submitLines}
            text={"Générer les lignes"}
            styles={"btnValidation"}
          />
          {newDataList.map((line, index) => (
            <div key={index}>
              <span>Ligne {index + 1}</span>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default DiracLicence;
